@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Rodin';
  src: url('fonts/FOT-Rodin\ Pro\ DB.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@import-normalize;

:root {
  --black: #000000;
  --accent-color: #f9bc60;
  --off-white: #F5EFED;
  --dark-green: #0F3F32;
  --med-green: #058264; 
  --bg-color: #030117;
  --fs-xs: 0.8rem;
  --fs-sml: 1rem;
  --fs-med: 1.5rem;
  --fs-xl: clamp(1.4rem, 4vw, 2.5rem);
  --lvl-front: 1;
  --lvl-base: 0;
  --lvl-back: -1;
  --lvl-far-back: -2;
  --lvl-bg: -3;
}

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  overflow-wrap: break-word;

}

html, body, div {
  height: 100%;
  text-align: left;
  font-size: 16px;
  font-family: Rodin;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  html {
    font-size: 22px;
  }

  .tl-item {
    width: 30%;
  }

  .tl-item:nth-child(odd) {
    justify-content: flex-start;
    align-self: flex-start;
    margin-left: 17%;
  }
  
  .tl-item:nth-child(even) {
    justify-content: end;
    align-self: flex-end;
    margin-right: 17%;
  }
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}


body {
  background-color: var(--bg-color);
  text-align: center;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}

p {
  color: var(--dark-green);
}

.z-index-fix {
  -webkit-transform: translate3d(0,0,0);
}

.face {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: var(--lvl-base);
}
